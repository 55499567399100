import router from "../router";

export const guardarUsuario = ({ commit }, payload) => {
    localStorage.setItem("token", payload);

    commit("obtenerUsuario", payload);
  }


export const cerrarSesion = ({ commit }) => {
    commit("obtenerUsuario", "");
    localStorage.removeItem("token");
    localStorage.removeItem("usuarioDB");
    localStorage.removeItem("sessionID");
    router.push({ name: "loginview" });
  }


export const leerToken = ({ commit }) => {
    const token = localStorage.getItem("token");
    if (token) {
      commit("obtenerUsuario", token);
    } else {
      commit("obtenerUsuario", "");
      
    }
  }