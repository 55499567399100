<template>
  <v-container>
      <h1>Bienvenido a tu DistriPos</h1><br>
      <v-row>
          <!-- punto de venta -->
       
          <v-col cols="12" md="4" v-if="role === 'ADMIN' || role === 'SALES AGENT' || role === 'STORER'  ">
            
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'NewOrder'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Nueva Orden
                          </v-list-item-title>
                          <v-list-item-subtitle> Realiza una Orden para realizar una Venta</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/newOrder.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>
          <v-col cols="12" md="4" v-if="role === 'ADMIN' || role === 'SALES AGENT' || role === 'STORER'  ">
            
            <v-card
                elevation="11"
                outlined
                shaped
                @click="$router.push({ name: 'recibosdigitales'})"
                >
                <v-list-item three-line>
                    <v-list-item-content>
                        
                        <v-list-item-title class="text-h5 mb-1">
                           Recibo Digital
                        </v-list-item-title>
                        <v-list-item-subtitle> Realizar Recibo sobre pago/abono</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar
                        tile
                        size="80"
                        color="rgba(237, 231, 225, 0.2)"
                    >
                        <img src="../assets/img/payments.png" alt="">
                    </v-list-item-avatar>
                    </v-list-item>

            </v-card>
        </v-col>
          
          <v-col cols="12" md="4" v-if="role === 'ADMIN' || role === 'SALES AGENT' || role === 'STORER'  ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'expenses'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Cotizacion
                          </v-list-item-title>
                          <v-list-item-subtitle>Realiza una Cotizacion para tu Cliente</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/cotizacion.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>


          <v-col cols="12" md="4" v-if="role === 'ADMIN' || role === 'SALES AGENT' || role === 'STORER'  ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'orders'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Mis Ordenes
                          </v-list-item-title>
                          <v-list-item-subtitle>Aqui Encontraras Listada tus Ordenes</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/orders.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>

          <v-col cols="12" md="4" v-if="role === 'ADMIN' ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'balancesAndPayments'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Saldos y Abonos
                          </v-list-item-title>
                          <v-list-item-subtitle>Visualiza saldo de Clientes y agrega Abonos o Pagos</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/payments.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>
          <v-col cols="12" md="4" v-if="role === 'ADMIN' ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'notasdecredito'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Realiza una Nota de Credito
                          </v-list-item-title>
                          <v-list-item-subtitle>Realiza una nota de Credito</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/devolucion.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>


          <v-col cols="12" md="4" v-if="role === 'ADMIN' || role === 'SALES AGENT' || role === 'STORER'  ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'customers'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Clientes
                          </v-list-item-title>
                          <v-list-item-subtitle>Listado de Clientes</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/customers.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>

          <v-col cols="12" md="4" v-if="role === 'ADMIN' ||  role === 'STORER'  ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'products'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Catalogo Productos
                          </v-list-item-title>
                          <v-list-item-subtitle>Catalogo de tus Productos/Servicios</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/products.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>

          <v-col cols="12" md="4" v-if="role === 'ADMIN' ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'reports'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Reportes
                          </v-list-item-title>
                          <v-list-item-subtitle>Reportes de ventas, productos, clientes.</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/reports.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>

          <v-col cols="12" md="4" v-if="role === 'ADMIN' ||  role === 'STORER'  ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'pendingorders'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Ordenes Pendientes
                          </v-list-item-title>
                          <v-list-item-subtitle>Visualiza ordenes Pendientes de Armar o Entregar</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/orders.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>
          <v-col cols="12" md="4" v-if="role === 'ADMIN' || role === 'STORER'  ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'pendingordersdelivery'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Ordenes Listas <br> Para Entrega
                          </v-list-item-title>
                          <v-list-item-subtitle>Ordenes listas Asignar a un Chofer</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/orders.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>
          <v-col cols="12" md="4" v-if="role === 'ADMIN' || role === 'STORER' || role === 'DRIVER'  ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'ordersAsign'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Ordenes en <br> Ruta
                          </v-list-item-title>
                          <v-list-item-subtitle>Ordenes asignadas para Entrega</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/orders.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>


          <v-col cols="12" md="4" v-if="role === 'ADMIN' ||  role === 'STORER'  ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'almacen'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                            Menu de Almacen
                          </v-list-item-title>
                          <v-list-item-subtitle>Entradas/Salidas, Inventario etc.</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/almacen.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>

          <v-col cols="12" md="4" v-if="role === 'ADMIN' ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'comisiones'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Cal. Comisiones
                          </v-list-item-title>
                          <v-list-item-subtitle>Realiza Calculo de Comisiones</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/comisiones.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>


          <v-col cols="12" md="4" v-if="role === 'ADMIN' ">
              <v-card
                  elevation="11"
                  outlined
                  shaped
                  @click="$router.push({ name: 'settings'})"
                  >
                  <v-list-item three-line>
                      <v-list-item-content>
                          
                          <v-list-item-title class="text-h5 mb-1">
                             Configuraciones
                          </v-list-item-title>
                          <v-list-item-subtitle>Realiza Cambios en Tu Cuenta y App</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                          tile
                          size="80"
                          color="rgba(237, 231, 225, 0.2)"
                      >
                          <img src="../assets/img/settings.png" alt="">
                      </v-list-item-avatar>
                      </v-list-item>

              </v-card>
          </v-col>


      </v-row>
  </v-container>
</template>


<script>
import store from "../store";
export default {
  data(){
      return {
          role: store.state.usuarioDB.data.role
      }
  },
  mounted(){
    console.log(this.role)
    if(this.role === "CUSTOMER"){
        this.$router.push({ name: "customerpanel"})
    }
  }
}

</script>