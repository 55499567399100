import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import Notifications from 'vue-notification'
import VuetifyConfirm from 'vuetify-confirm'
import './registerServiceWorker'
import JsonExcel from "vue-json-excel";
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'



Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false
Vue.use(Notifications)
Vue.use(VuetifyConfirm, { vuetify })
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})


const miMixinGlobal = {
  methods: {
    convertmoneyformatt(number){
      let n = Number(number).toFixed(2)
      const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
      });
      return formatter.format(n)
  },
  },
};

Vue.mixin(miMixinGlobal);


//axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.baseURL = 'https://api.distripos.app';


const preload = async () => {
  store.dispatch('leerToken');
    new Vue({
      vuetify,
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");

}
preload();