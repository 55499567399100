import store from "../store";
export default {


    isAuthenticated() {
     if(store.state.token === ""){
        return false;
     }else{
        return true
     }
        
    },
    hasRole(userRole, requiredRole) {
      // Lógica para verificar si el usuario tiene un rol específico
        
        if (requiredRole.includes(userRole)) {
            return true;
        } else {
            // alert("No Authorized")
           return false
        }
    },
  };