<template>
  <v-app>

             <v-btn
              v-if="estaActivo"
              color="pink"
              class="btnSalir"
              dark
              x-small
              absolute
              top
              right
             
              @click="cerrarSesion()"
            >
              <small>Cerrar Sesion</small>
            </v-btn>
    <v-main>
      <router-view/>
    </v-main>

    <notifications position="top center" group="success" />
    <notifications position="top left"  group="erros" />

    
    <v-btn
      v-if="estaActivo"
        class="mx-2 btnReturn"
        fab
        dark
        @click="returnpage()"
        color="red"
      >
        <v-icon dark>
          mdi-arrow-left
        </v-icon>
     </v-btn>
     <!-- <v-btn
      v-if="estaActivo"
        small
        class="mx-2 btnTheme"
        fab
        dark
        @click="changeTheme()"
        color="grey"
      >
        <v-icon dark>
          mdi-brightness-6
        </v-icon>
     </v-btn> -->

  </v-app>

</template>

<script>
// import axios from 'axios';
// import store from "./store";
import { mapActions, mapGetters } from "vuex";
export default {
  name: 'App',
  

  data: () => ({
    //
  }),


  methods:{
    ...mapActions(["cerrarSesion", "leerToken"]),
    returnpage(){
        window.history.go(-1); return false;
      },

  },

  computed: {
            //  ...mapGetters(["estaActivo"]),
            ...mapGetters(["estaActivo"])
            
       },
 
      created() {
        this.leerToken();
        
      },
      mounted(){
       
       

      },


};
</script>


<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

h1 {
  color: orange;
}
.btnReturn{
  bottom:10px;left:10px;position:fixed;z-index:9999;
}
.btnTheme{
  bottom:10px;right:10px;position:fixed;z-index:9999;
}
.btnSalir{
  top:10px;right:10px;position:fixed;z-index:9999;
}
</style>