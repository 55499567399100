import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
// var theme = localStorage.getItem("theme");
// console.log(theme)
// if(theme === null){
//     theme = false;
// }
export default new Vuetify({
   
    theme: { dark: true }

});
